.subsubheading {
    margin: 0;
    color: #111349;
}

.counterContainer {
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.counterInput {
    width: 60px;
    text-align: center;
    border: none;
    outline: none;
    background: transparent;
    font-size: 16px;
    color: white;
}

.serviceTabs,
.chatWrapper,
.inputsBlock {
    width: 100%;
    max-width: 1200px;
}

/* chatWrappers */
.inputsBlock {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.chatWrapper {
    max-height: 0;
    opacity: 0;
    transform: translateY(-12px);
    transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.4s ease-in-out;
}

.chatVisible {
    max-height: 1000px;
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.05s;
}

.chatBoxWrapper {
    position: relative;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 10px 5px rgb(0 0 0 / 50%);
    overflow: hidden;
}

.chatSidebar {
    height: 600px;
}

.chatSidebar {
    position: absolute;
    left: 0;
    width: 250px;
    background-color: #ffffff;
    border-right: 1px solid #ddd;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
    z-index: 10;
}

.chatSidebar ul {
    list-style: none;
    padding: 0;
    margin-top: 10px;
}

.chatSidebar li {
    padding: 6px 0;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.chatSidebar li:hover {
    color: #111349;
    font-weight: 500;
}

/* mailingContainer */
.mailingContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-left: 15%;
}

.inputContainer {
    opacity: 0.9;
    position: relative;
    top: 27px;
    width: 300px;
    padding: 10px;
}

textarea {
    display: block;
    background-color: #111349;
    width: 100%;
    height: 140px;
    padding: 10px;
    font-size: 16px;
    color: #ffffff;
    border: none;
    transition: all 0.1s ease;
}

textarea::placeholder {
    color: #ffffff;
    font-weight: 100;
}

/* Стили для OptionBox с эффектом hover */
.optionBox {
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.saveBox {
    justify-content: center;
    display: flex;
    background-color: #111349;
    margin-top: 5px;
    width: 94%;
    padding: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    align-items: center;
    color: #ffffff;
    position: relative;
    cursor: pointer;
}

.saveBox:hover {
    background-color: #c7c7c7;
    color: #111349;
}

.saveButton {
    cursor: pointer;
}


.payment_text {
    display: inline-table;
    margin-right: 10px;
}

.payment_input {
    border: 1px solid #ccc;
    font-size: 16px;
    width: 80px;
    padding: 5px;
    border-radius: 5px;
}

.label {
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}

.icon {
    width: 15px;
    padding-right: 5px;
}

.numberText {
    background-color: #111349;
    color: #ffffff;
    font-size: 14px;
    padding: 12px 16px;
    border-radius: 0;
    line-height: 1.5;
    border: 1px solid #22274a;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

/* Контейнер для выпадающего списка */
.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Кнопка выбора */
.dropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.dropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

/* Скрытый список */
.dropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    border: 1px solid #ccc;
    max-height: 200px;
    /* Ограничение высоты */
    overflow-y: auto;
    /* Добавляем скролл */
    scrollbar-width: thin;
    /* Для Firefox */
    scrollbar-color: #111349 #c7c7c7;
}

/* Элементы списка */
.dropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Эффект наведения */
.dropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

.dropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}



/* Стили для модального окна */
/* Переключатель режима ввода */
.switchButtons {
    display: flex;
    justify-content: space-around;
    margin-bottom: 15px;
}

.switchButton {
    flex: 1;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #f5f5f5;
    transition: background-color 0.3s;
}

.switchButton:hover {
    background-color: #ddd;
}

.switchButton.active {
    background-color: #111349;
    color: white;
}

/* Файл-инпут */
.fileInput {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.fileName {
    margin-top: 10px;
    font-weight: bold;
}

/* Основные стили модального окна */
.warningText {
    color: red;
    font-weight: bold;
    font-size: 25px;
}

.modelDropdownContainer {
    position: relative;
    display: inline-block;
    width: 100%;
}

/* Кнопка выбора */
.modelDropdownButton {
    width: 100%;
    background: none;
    color: white;
    padding: 10px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    text-align: left;
    background-color: #111349;
}

.modelDropdownButton:hover {
    background-color: #c7c7c7;
    color: #111349;
}

/* Открытый список */
.modelDropdownList {
    display: block;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #c7c7c7;
    list-style: none;
    padding: 0;
    margin: 0;
    min-width: 150px;
    z-index: 1000;
    border: 1px solid #ccc;
    max-height: 200px;
    /* Ограничение высоты */
    overflow-y: auto;
    /* Скролл при переполнении */
}

/* Элементы списка */
.modelDropdownList li {
    padding: 10px;
    color: #111349;
    cursor: pointer;
    transition: background-color 0.3s;
}

/* Эффект наведения */
.modelDropdownList li:hover {
    background-color: #111349;
    color: #ffffff;
}

/* Показываем список при наведении */
.modelDropdownContainer:hover .modelDropdownList {
    display: block;
}

.modal {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modalContent {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 500px;
    text-align: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    border-radius: 10px;
}

.modalContent h2 {
    margin-top: 10px;
}

textarea {
    width: 96%;
    height: 100px;
    margin: 10px 0;
    padding: 10px;
    font-size: 16px;
}

.uploadButton {
    background-color: #111349;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    margin-top: 10px;
}

.uploadButton:hover {
    background-color: #0e103f;
}

.close {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 24px;
    cursor: pointer;
}





/* Маленькое модальное окно */
.modalContent.small {
    width: 300px;
    padding: 15px;
}

/* Блоки сервисов */
.serviceContainer {
    display: flex;
    flex-direction: column;
    width: 700px;
}

/* serviceTabs. Социальные сети контейнер выбора */
.serviceTabs {
    display: flex;
    width: 100%;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(17, 19, 73, 0.1);
}

.tab,
.activeTab {
    flex: 1;
    padding: 12px 0;
    background-color: #f4f6fc;
    border: none;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #111349;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
}

.tab:not(:last-child),
.activeTab:not(:last-child) {
    border-right: 1px solid #e0e4f3;
    /* очень лёгкая граница */
}

.tab:hover {
    background-color: #e1e5f2;
}

.activeTab {
    background-color: #111349;
    color: #ffffff;
    font-weight: 600;
}

/* Красивая анимация подчеркивания для активной кнопки */
.activeTab::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    background-color: white;
    /* контрастный акцентный цвет */
    animation: underline 0.3s ease;
}

@keyframes underline {
    from {
        transform: scaleX(0);
    }

    to {
        transform: scaleX(1);
    }
}

.serviceBlocks {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 20px
}

.serviceBlock {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.serviceLogo {
    width: 200px;
    height: 50px;
    padding: 10px;
}

.counterBlock {
    justify-content: space-around;
    color: #ffffff;
    min-width: 200px;
    max-width: 200px;
    padding: 10px;
    text-align: center;
    background: #111349;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    opacity: 0.9;
}

.counterButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

.counter {
    font-size: 24px;
    margin: 10px 0;
}

.costText {
    color: #ffffff;
}

.savedTextContainer {
    width: 100%;
    margin-top: 20px;

    flex-direction: column;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 1000px;
    opacity: 0.9;
}

.savedText {
    word-wrap: break-word;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 10px;
    background-color: #111349;
    color: #c7c7c7;
    width: -webkit-fill-available;
}

.savedText span {
    flex: 1;
    margin-right: 10px;
}

.editButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

.deleteButton {
    margin: 5px;
    padding: 10px 15px;
    font-size: 16px;
    cursor: pointer;
    background-color: #111349;
    color: white;
    border: none;
}

@media only screen and (max-width: 768px) {
    .savedText {
        width: 370px;
    }

    .mailingContainer {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        margin-left: none;
    }

    .serviceContainer {
        width: 300px;
    }

    .chatSidebar {
        display: none;
    }

    .chatHeaderMobile {
        display: block;
        margin-bottom: 10px;
    }

    .dropdownToggle {
        width: 100%;
        padding: 10px 14px;
        font-size: 16px;
        background: #111349;
        color: white;
        border: none;
        border-radius: 8px;
        cursor: pointer;
    }

    .chatDropdown {
        list-style: none;
        padding: 0;
        margin-top: 8px;
        background: #f9f9f9;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    }

    .chatDropdown li {
        padding: 10px 14px;
        border-bottom: 1px solid #ddd;
        cursor: pointer;
    }

    .chatDropdown li:last-child {
        border-bottom: none;
    }

    .chatDropdown li:hover {
        background-color: #eaeaea;
    }
}

/* Desktop: скрываем dropdown */
@media (min-width: 769px) {
    .chatHeaderMobile {
        display: none;
    }
}

/* Статус бар для рассылок */
.campaignsList {
    display: flex;
    flex-direction: column;
}

.campaignRow {
    display: flex;
    align-items: center;
    background-color: #111349;
    border: 1px solid #22274a;
    border-radius: 0;
    /* острые углы */
    padding: 12px 16px;
    color: white;
}

.campaignInfoGrid {
    display: flex;
    justify-content: space-between;
    padding-bottom: 12px;
    border-bottom: 1px solid #2a2d5a;
    margin-bottom: 16px;
    color: #ffffff;
    font-size: 14px;
}

.sectionHeader {
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    color: #cfd2ff;
}

.recipientsGrid {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    padding-right: 4px;
}

.recipientRow {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    font-size: 14px;
    color: #e0e0e0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.recipientNumber {
    color: #ffffff;
}

.recipientStatus {
    font-weight: bold;
}

.infoButton {
    padding: 6px 12px;
    font-size: 14px;
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
    cursor: pointer;
    margin-right: 12px;
}

.infoButton:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.launchButton {
    padding: 6px 14px;
    font-size: 14px;
    background-color: #25d366;
    color: #111349;
    border: none;
    cursor: pointer;
    font-weight: 600;
}

.launchButton:disabled {
    background-color: #ccc;
    color: #777;
    cursor: not-allowed;
}

.statusNumbers {
    margin-left: auto;
    font-size: 14px;
    color: #fff;
    font-weight: 500;
}

.campaignDetails {
    background-color: #111349e6;
    color: #cfcfcf;
    padding: 12px 16px;
    border-radius: 0;
    font-size: 13px;
    animation: fadeIn 0.3s ease;
}

.recipientsBlock {
    margin-top: 16px;
    border-top: 1px solid #444;
    padding-top: 12px;
}

.recipientsBlock h4 {
    margin-bottom: 6px;
    font-size: 14px;
    color: #ffffff;
}

.recipientsBlock ul {
    padding-left: 18px;
    margin-bottom: 12px;
    font-size: 13px;
    color: #dcdcdc;
}

.recipientsBlock li {
    margin-bottom: 4px;
}

.recipientsGrid {
    display: flex;
    flex-direction: column;
    max-height: 300px;
    overflow-y: auto;
    margin-top: 16px;
    padding: 0 4px;
    border-top: 1px solid #444;
}

.recipientRow {
    display: flex;
    justify-content: space-between;
    padding: 6px 0;
    font-size: 14px;
    color: #e0e0e0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.recipientNumber {
    color: #ffffff;
}

.recipientStatus {
    font-weight: bold;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-6px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.campaignProgress {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-right: 12px;
}

.progressCircle {
    width: 28px;
    height: 28px;
    border: 2px solid #fff;
    border-radius: 50%;
    position: relative;
}

.clockHand {
    position: absolute;
    top: 3px;
    left: 50%;
    width: 2px;
    height: 10px;
    background-color: white;
    transform-origin: bottom center;
    animation: rotateHand 4s linear infinite;
    /* ⬅️ БЫЛО 1s, теперь 4s */
}

@keyframes rotateHand {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.progressText {
    color: white;
    font-size: 13px;
    font-weight: 500;
}

/*  */